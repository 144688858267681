import { useEffect, useState } from 'react';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import Component404 from '@mdc/ui/src/404/Component404';
import { COOKIE_OPSWAT_REDIRECT } from '@mdc/constants';
import Cookie from 'js-cookie';

const Template404 = () => {
    const [windowLocation, setWindowLocation] = useState({ href: '/' });

    useEffect(() => {
        // eslint-disable-next-line no-useless-escape
        Cookie.set(COOKIE_OPSWAT_REDIRECT, windowLocation.href.match(/(https:\/\/)?[^/\?]*/)[0], { path: '/', secure: true });
    }, [windowLocation] );

    useEffect(() => {
        setWindowLocation(window && window.location);
    }, []);

    return (
        <Layout pageTitle="404" pageDescription={'Not found'}>
            <Component404/>
        </Layout>
    );
};

export default Template404;
